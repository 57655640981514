<template>
  <div>
    <canvas></canvas>
    <div v-if="error" class="warning text-center" :style="`width: ${size}`">Error</div>
    <v-progress-circular v-if="loading" indeterminate color="primary" class="ma-4" :size="size/1.5"/>
  </div>
</template>

<script>
import QRCode from "qrcode";

export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      required: false,
      default: 100,
    },
  },
  data: () => {
      return { 
          loading: true,
          error: false
      }
  },
  watch: {
    value() {
        this.generateQRCode();
    },
    // size(){
    //     console.log("size change, regenerate")
    //     this.generateQRCode();
    // }
  },
  mounted() {
      this.generateQRCode();
  },
  methods: {
    generateQRCode() {
      var canvas = this.$el.querySelector("canvas");
      const options = {
          width: this.size
      }
      QRCode.toCanvas(canvas, this.value, options).then((resp) => {
        this.$emit("generated", resp);
        this.loading = false;
      })
      .catch(() => {
          this.error = true;
          this.loading = false;
      })
    },
  },
};
</script>

<style></style>
